<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="topnews" id="MyOffer_k">
    <div class="container-fluid px-md-4">
      <div class="row">
        <div class="col-6 col-md-8">
          <div class="offer">
            <div class="d-flex align-items-center">
              <p class="m-0 font-1"> <i class="fa fa-phone me-1"></i>+91 - {{companyService.companyData?.Hotellist_Model?.ContactNo}} </p>
              <span class="mx-2">|</span>
              <p class="m-0 font-2  hide-phone"> (10 AM to 6 PM ) Monday to Saturday</p>
              <span class="mx-2  hide-phone">|</span>
              <p class="m-0 font-2  hide-phone">📢 {{Header1?.Description}} </p>
              <span class="mx-2  hide-phone">|</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="top-menu-item">
            <ul class="text-right">
              <!-- <li><p class="m-0 px-2">📢 {{Header1?.Description}} </p></li> -->
              <!-- <li> <a> {{companyService.companyData?.Hotellist_Model?.ContactNo}} </a> </li> -->
              <li> <a class="font-1" [routerLink]="['/pages/contact']"> Contact Us </a> </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="top-header" *ngIf="topbar">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-4 col-md-3 col-lg-3">
          <div class="header-contact">
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <img [src]="env.mediaUrl+(companyService?.companyData?.Hotellist_Model?.HotelNameLoge || 'no-img.png')"
                  class="img-fluid logo" alt="logo" title="logo" width="140" height="54">
              </a>
            </div>
            <ul class="d-none">
              <li [textContent]="companyService?.companyData?.Hotellist_Model?.HotelName | slice:0:200">Lorem ipsum
                dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, </li>
              <li translate><i class="fa fa-phone" aria-hidden="true"></i>Call Us: <a
                  [attr.href]="'tel:'+companyService?.companyData?.Hotellist_Model?.ContactNo"
                  [textContent]="companyService?.companyData?.Hotellist_Model?.ContactNo"></a></li>
            </ul>
          </div>
        </div>
        <div class="col-8 col-md-9 col-lg-9">
          <div class=" d-flex align-items-center justify-content-end">
            <div class="header-search">
              <div class="input-group">
                <div appNestedDropdown ngbDropdown>
                  <div class="select-categories">
                    <button class="btn btn-success our-prd-btn" ngbDropdownToggle>
                      <i class="fa fa-bars pe-2"></i> Our Products
                    </button>
                  </div>
                  <div ngbDropdownMenu>
                    <div ngbDropdown *ngFor="let menuItem of shopCategoryMenu?.slice(0,7)">
                      <div class="align-items-center d-flex dropdown-item justify-content-between" ngbDropdownAnchor>
                        {{ menuItem.title | translate }}
                        <span class="fa fa-chevron-right ml-4"></span>
                      </div>
                      <div ngbDropdownMenu>
                        <button class="dropdown-item" type="button" *ngFor="let childrenItem of menuItem.children"
                          [routerLink]="[childrenItem.path,childrenItem.paramObj.replaceAll(' ', '-')]"
                          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ childrenItem.title |
                          translate }}</button>

                      </div>
                    </div>
                    <ng-container *ngFor="let tag of productService.ItemsTag">
                      <div ngbDropdown  *ngIf="tag.IsMenu">
                        <div class="align-items-center d-flex dropdown-item justify-content-between" ngbDropdownAnchor [routerLink]="['/collections/'+tag.ItemTagName]">
                          {{ tag.ItemTagName | translate }}
                        </div>                      
                      </div>
                    </ng-container>
                  </div>
                </div>
                <ng-select [dropdownPosition]="'bottom'" id="ItemId" name="ItemId" appendTo="body" class="custom-select"
                  (change)="selectChange($event)">
                  <ng-template ng-header-tmp>
                    <div class="form-group has-feedback">
                      <input style="width: 100%;line-height: 24px" type="text" class="form-control" id="inputValidation"
                        placeholder="Search" [(ngModel)]="searchItem" [ngModelOptions]="{standalone: true}" />
                    </div>
                  </ng-template>
                  <ng-option *ngFor="let car of availableProduct | filterByImpure:['ItemName']:searchItem"
                    [value]="car.ItemName"> {{car.ItemName | translate}}</ng-option>
                  
                </ng-select>
              </div>
            </div>
            <div class="topright-menu">
              <ul class="header-dropdown">
                <li class="mobile-wishlist">
                  <button class="btn btn-solid bg-transparent border-0 p-0"
                    style="background-image: none; color: #000;font-weight: 400; text-transform: capitalize;"
                    *ngIf="this.code==1" (click)="loginvendor()"><i class="fa fa-unlock-alt"></i>Vendor Login</button>
                </li>

                <li class="onhover-dropdown mobile-account">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <span [textContent]="authService.getUserName() || 'My Account'"></span>
                  <ul class="onhover-show-div">
                    <li *ngIf="!authService.isUserLoggedIn()">
                      <a data-lng="en" [routerLink]="['/auth/login']" title="login">
                        Login
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/dashboard']" title="Dashboard">
                        Dashboard
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/profile']" title="Profile">
                        Profile
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/orders']" title="History">
                        Order History
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/wishlist']" title="Wishlist">
                        Wishlist
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" (click)="authService.logout()" title="Logout">
                        Logout
                      </a>
                    </li>
                    <li *ngIf="!authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/auth/register']" title="Register">
                        Register
                      </a>
                    </li>
                  </ul>
                </li>

                <li class="mobile-wishlist">
                  <a title="wishlist" [routerLink]="['/shop/wishlist']"><i class="fa fa-heart"
                      aria-hidden="true"></i>{{'wishlist' | translate}}</a>
                </li>
                
                <li>
                  <div class="seating-par top-seating-part">
                    <app-settings></app-settings>
                  </div>
                </li>

                <li class="currency-list">
                  <select class="form-select">
                    <option>INR</option>
                  </select>
                </li>

              </ul>
              <!-- <div class="seating-par top-seating-part">
                <app-settings></app-settings>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div appNestedDropdown ngbDropdown>
            <div class="select-categories">
              <button class="btn btn-success our-prd-btn" ngbDropdownToggle>
                <i class="fa fa-bars pe-2"></i> Shop
              </button>
            </div>
            <div ngbDropdownMenu>
              <div ngbDropdown *ngFor="let menuItem of shopCategoryMenu?.slice(0,7)">
                <div class="align-items-center d-flex dropdown-item justify-content-between" ngbDropdownAnchor>
                  {{ menuItem.title | translate }}
                  <span class="fa fa-chevron-right ml-4"></span>
                </div>
                <div ngbDropdownMenu>
                  <button class="dropdown-item" type="button" *ngFor="let childrenItem of menuItem.children"
                    [routerLink]="[childrenItem.path,childrenItem.paramObj.replaceAll(' ', '-')]"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ childrenItem.title |
                    translate }}</button>

                </div>
              </div>
               <ng-container *ngFor="let tag of productService.ItemsTag">
              <div ngbDropdown  *ngIf="tag.IsMenu">
                <div class="align-items-center d-flex dropdown-item justify-content-between" ngbDropdownAnchor [routerLink]="['/collections/'+tag.ItemTagName]">
                  {{ tag.ItemTagName | translate }}
                </div>                      
              </div>
            </ng-container>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-variation-menu></app-variation-menu>
            </div>
            <div class="seating-part d-none">
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>